import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

const comunicacao = {
    // Importar todas as telas aqui

    home_revista: lazy(() => import('views/comunicacao/revista-ola-brasal/Home')),
    home_revista_refrigerantes: lazy(() => import('views/comunicacao/revista-ola-brasal-refrigerantes/Home')),
    pontocom: lazy(() => import('views/comunicacao/pontocom/Index')),
    pergunte_diretor: lazy(() => import('views/comunicacao/pergunte-diretor/Pergunte')),
    pergunte_empresa: lazy(() => import('views/comunicacao/pergunte-empresa/PergunteEmpresaPerguntas')),
    pergunte_empresa_respostas: lazy(() => import('views/comunicacao/pergunte-empresa/PergunteEmpresaRespostas')),
    // pergunte_diretor_chat: lazy(() => import('views/pergunte-diretor/Chat')),
    // pergunte_empresa: lazy(() => import('views/pergunte-empresa/Index')),
    // pergunte_empresa_chat: lazy(() => import('views/pergunte-empresa/Chat')),
    perguntasFrequentes: lazy(() => import('views/conteudo/PerguntasFrequentes')),
    novidades: lazy(() => import('views/comunicacao/novidades/Novidades')),
    novidade: lazy(() => import('views/comunicacao/novidades/Novidade')),
    novidades_brasal: lazy(() => import('views/comunicacao/novidades-brasal/Novidades')),
    novidade_brasal: lazy(() => import('views/comunicacao/novidades-brasal/Novidade')),
    pagina: lazy(() => import('views/comunicacao/paginas/Pagina')),
    eventos: lazy(() => import('views/comunicacao/eventos/Eventos')),
    noticiasLocais: lazy(() => import('views/comunicacao/noticias-locais/Index')),
    classificados: lazy(() => import('views/comunicacao/classificados/Classificados')),
    classificadosDetalhe: lazy(() => import('views/comunicacao/classificados/ClassificadosDetalhe')),
    classificadosEdicao: lazy(() => import('views/comunicacao/classificados/ClassificadosEdicao')),
    portalCentroCorporativo: lazy(() => import('views/comunicacao/portal-centro-corporativo/Home')),
};
const conteudo = {
    SidebarMenuProgramas: lazy(() => import('views/programas/SidebarMenuProgramas')),
    PagePrograma: lazy(() => import('views/programas/PagePrograma')),
    SidebarMenuSistemasImportantes: lazy(() => import('views/sistemas-importantes/SidebarMenuSistemasImportantes')),
    PageSistemasImportantes: lazy(() => import('views/sistemas-importantes/PageSistemasImportantes')),
    cardapio: lazy(() => import('views/conteudo/Cardapio')),
    colaboradorDoMesDepartamento: lazy(() => import('views/colaborador-do-mes/ColaboradorDoMesPorDepartamento')),
    convenios: lazy(() => import('views/conteudo/Convenios')),
    ramais: lazy(() => import('views/conteudo/EncontreUmRamal')),
    baixeAi: lazy(() => import('views/conteudo/baixe-ai/BaixeAi')),
    baixeAiArquivos: lazy(() => import('views/conteudo/baixe-ai/BaixeAiArquivos')),
};
const processo = {
    solicitarFerias: lazy(() => import('views/processos/solicitar-ferias/SolicitarFerias')),
    vagas: lazy(() => import('views/processos/vagas/Index')),
    sua_opniao_list: lazy(() => import('views/processos/sua-opniao/SuaOpniaoList')),
    sua_opniao: lazy(() => import('views/processos/sua-opniao/SuaOpniao')),
    bancoHoras: lazy(() => import('views/processos/banco-horas/BancoDeHoras')),
    contraCheque: lazy(() => import('views/processos/contra-cheque/ContraCheque')),
};

const colaboracao = {
    encontreUmEspecialista: lazy(() => import('views/encontre-um-especialista/EncontreUmEspecialista')),
    falaBrasal: lazy(() => import('views/colaboracao/fala-brasal/FalaBrasal')),
    //inove_ai: lazy(() => import('views/inove-ai/SidebarMenuInoveAi')),
    inove_ai: lazy(() => import('views/colaboracao/inove-ai/InoveAi')),
    pense_rapido: lazy(() => import('views/colaboracao/pense-rapido/PenseRapido')),
    compartilheConhecimento: lazy(() => import('views/colaboracao/compartilhe-conhecimento/CompartilheConhecimento')),
};
const segment = localStorage.getItem('segmentoPath');

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;
const sidebarMenu = [
    // comunicação
    {
        path: `${appRoot}/comunicacao`,
        label: 'COMUNICAÇÃO',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_comunicacao',
    },

    {
        path: '/comunicacao/novidades',
        label: 'Novidades',
        icon: 'news',
        component: comunicacao.novidades,
        subs: [{ path: '/:segmento/:id', component: comunicacao.novidade }],
    },
    {
        path: '/comunicacao/novidades-brasal',
        label: 'Novidades Brasal',
        icon: 'news',
        component: comunicacao.novidades_brasal,
        subs: [{ path: '/:segmento/:id', component: comunicacao.novidade_brasal }],
    },
    {
        path: '/comunicacao/portal-centro-corporativo',
        label: 'Portal Centro Corporativo',
        icon: 'news',
        component: comunicacao.portalCentroCorporativo,
        subs: [{ path: '/:segmento/:id', component: comunicacao.portalCentroCorporativo }],
    },
    {
        path: '/comunicacao/pagina/:id',
        label: 'Notícias Locais',
        icon: 'news',
        component: comunicacao.pagina,
        isDivider: false,
        subs: [{ path: '/slug/:slug', component: comunicacao.pagina }],
    },

    {
        path: '/comunicacao/revista-ola-brasal',
        label: 'Revista Olá Brasal',
        icon: 'light-off',
        component: comunicacao.home_revista,
        isDivider: false,
    },
    {
        path: '/comunicacao/revista-brasal-refrigerantes',
        label: 'Revista Brasal Refrigerantes',
        icon: 'light-off',
        component: comunicacao.home_revista_refrigerantes,
        isDivider: false,
    },
    {
        path: '/comunicacao/pontocom',
        label: 'Ponto.Com',
        icon: 'light-off',
        component: comunicacao.pontocom,
        isDivider: false,
    },
    {
        path: '/comunicacao/portal-centro-corporativo',
        label: 'Portal Centro Corporativo AAAA',
        icon: 'light-off',
        component: comunicacao.portalCentroCorporativo,
        isDivider: false,
    },
    {
        path: '/comunicacao/calendario-eventos',
        label: 'Calendário de Eventos',
        icon: 'calendar',
        component: comunicacao.eventos,
        isDivider: false,
    },
    { path: '/comunicacao/noticias-locais', label: 'Notícias Locais', icon: 'news', component: comunicacao.noticiasLocais, isDivider: false },
    {
        path: '/comunicacao/classificados',
        label: 'Classificados',
        icon: 'news',
        component: comunicacao.classificados,
        subs: [
            { path: '/detalhe/:id', component: comunicacao.classificadosDetalhe },
            { path: '/edicao/:id', component: comunicacao.classificadosEdicao },
            { path: '/novo', component: comunicacao.classificadosEdicao },
        ],
    },
    {
        path: `/comunicacao/pergunte-diretor`,
        label: 'Pergunte ao Diretor',
        icon: 'messages',
        component: comunicacao.pergunte_diretor,
        isDivider: false,
    },
    {
        path: `${appRoot}/pergunte-diretor-chat`,
        component: comunicacao.pergunte_diretor_chat,
        isDivider: false,
    },
    {
        path: `/comunicacao/pergunte-empresa`,
        label: 'Pergunte à Empresa',
        icon: 'building',
        component: comunicacao.pergunte_empresa,
        subs: [{ path: '/:id', component: comunicacao.pergunte_empresa_respostas }],
        isDivider: false,
    },
    {
        path: `${appRoot}/pergunte-empresa-chat`,
        component: comunicacao.pergunte_empresa_chat,
        isDivider: false,
    },
    // Conteúdo
    {
        path: `${appRoot}/conteudo`,
        label: 'CONTEÚDO',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_conteudo',
    },
    {
        path: '/conteudo/programas-destaque',
        label: 'Programas em Destaque',
        icon: 'news',
        component: conteudo.SidebarMenuProgramas,
        subs: [{ path: '/:id', component: conteudo.PagePrograma }],
        isDivider: false,
    },
    {
        path: '/conteudo/baixe-ai',
        label: 'Baixe Aí',
        icon: 'light-off',
        component: conteudo.baixeAi,
        isDivider: false,
    },
    {
        path: '/conteudo/baixe-ai-arquivos',
        //label: 'Baixe Aí',
        //icon: 'light-off',
        component: conteudo.baixeAiArquivos,
        isDivider: false,
    },
    {
        path: '/conteudo/sistemas-importantes',
        label: 'Sistemas Importantes',
        icon: 'light-off',
        component: conteudo.SidebarMenuSistemasImportantes,
        isDivider: false,
        subs: [{ path: '/:id', component: conteudo.PageSistemasImportantes }],
    },
    {
        path: '/conteudo/perguntas-frequentes',
        label: 'Perguntas Frequentes',
        icon: 'news',
        component: comunicacao.perguntasFrequentes,
        isDivider: false,
    },
    {
        path: '/conteudo/novo-aqui',
        label: 'Novo por aqui?',
        icon: 'news',
        component: comunicacao.novidades,
        isDivider: false,
    },
    {
        path: '/conteudo/ramais',
        label: 'Lista de Ramais',
        icon: 'news',
        component: conteudo.ramais,
        isDivider: false,
    },
    { path: '/conteudo/convenios', label: 'Convênios', icon: 'news', component: conteudo.convenios, isDivider: false },
    { path: '/conteudo/cardapio', label: 'Cardapio', icon: 'news', component: conteudo.cardapio, isDivider: false },

    // Colaboração
    {
        path: `${appRoot}/colaboracao`,
        label: 'COLABORAÇÃO',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_colaboracao',
    },
    // {
    //     path: '/colaboracao/colaborador-mes', label: 'Colaborador do mês', icon: 'news', component: conteudo.colaboradorDoMes,
    //     subs: [
    //         {path: '/:id', component: conteudo.colaboradorDoMesDepartamento}
    //     ]
    // },
    { path: '/colaboracao/colaborador-mes', label: 'Colaborador do mês', icon: 'news', component: conteudo.colaboradorDoMesDepartamento },
    {
        path: '/colaboracao/compartilhe-conhecimento',
        label: 'Compartilhe Conhecimento',
        icon: 'light-off',
        component: colaboracao.compartilheConhecimento,
    },
    {
        path: `${appRoot}/colaboracao`,
        label: 'CONHECIMENTO',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_conhecimento',
    },
    {
        path: '/colaboracao/sua-opiniao',
        label: 'Sua opinião',
        icon: 'news',
        component: processo.sua_opniao_list,
        subs: [{ path: '/:id', component: processo.sua_opniao }],
    },
    { path: '/colaboracao/inove-ai', label: 'Inove aí', icon: 'news', component: colaboracao.inove_ai },
    { path: '/colaboracao/fala-brasal', label: 'Fala Brasal', icon: 'news', component: colaboracao.falaBrasal },
    { path: '/colaboracao/pense-rapido', label: 'Pense Rápido', icon: 'news', component: colaboracao.pense_rapido },
    {
        path: '/colaboracao/encontre-especialista',
        label: 'Encontre um Especialista',
        icon: 'news',
        component: colaboracao.encontreUmEspecialista,
    },
    // Processos
    {
        path: `${appRoot}/processos`,
        label: 'PROCESSOS',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_processos',
    },
    { path: '/processos/solicitar-ferias', label: 'Solicitar Minhas Férias', icon: 'news', component: processo.solicitarFerias, isDivider: false },
    { path: '/processos/vagas', label: 'Vagas', icon: 'news', component: processo.vagas, isDivider: false },
    {
        path: '/processos/contra-cheque',
        label: 'Contracheque',
        icon: 'wallet',
        component: processo.contraCheque,
        isDivider: false,
    },
    { path: '/processos/banco-horas-hmg', label: 'Banco de Horas', icon: 'wallet', component: processo.bancoHoras, isDivider: false },

    {
        path: `${appRoot}/colaboracao`,
        label: 'Saiba Mais',
        exact: true,
        redirect: false,
        isDivider: true,
        id: 'category_menu_saiba_mais',
    },
];
export default sidebarMenu;
